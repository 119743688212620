const accordionComponents = document.querySelectorAll(".accordion");
if (accordionComponents.length > 0) {
  accordionComponents.forEach((accordionComponent) => {
    const accordionItems =
      accordionComponent.querySelectorAll(".accordion__item");

    accordionItems.forEach((accordion) => {
      const header = accordion.querySelector(".accordion__header");
      const content = accordion.querySelector(".accordion__content");

      header.addEventListener("click", () => {
        accordion.classList.toggle("active");

        if (!content.style.maxHeight) {
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.style.maxHeight = null;
        }
      });
    });
  });
}
