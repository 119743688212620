const tabs = document.querySelectorAll(".tab-header"), // Tabs list
  tabsHeader = document.querySelector(".tabs-header"), // Parent of tabs list
  tabsContent = document.querySelectorAll(".tab-content"); // Content List

const pagination = document.querySelector(".pagination");
const paginationNums = document.querySelectorAll(".pagination__num");

let currentIndex = 0;

// Hide items Function
function hideItems() {
  tabsContent.forEach((item) => {
    item.classList.remove("active");
  });

  tabs.forEach((item) => {
    item.classList.remove("active");
  });

  if (paginationNums.length > 0) {
    paginationNums.forEach((item) => {
      item.classList.remove("active");
    });
  }
}

// Show items Function
function showItems(i = 0) {
  tabsContent[i].classList.add("active");
  tabs[i].classList.add("active");
  paginationNums[i].classList.add("active");
  currentIndex = i;
}

hideItems();
showItems();

// Event delegation
tabsHeader.addEventListener("click", (event) => {
  const target = event.target;

  if (target && target.classList.contains("tab-header")) {
    tabs.forEach((item, i) => {
      if (target == item) {
        hideItems();
        showItems(i);
      }
    });
  }
});

pagination.addEventListener("click", (event) => {
  const target = event.target;

  if (
    (target && target.classList.contains("pagination__num")) ||
    target.classList.contains("pagination__arrow")
  ) {
    if (target.classList.contains("prev")) {
      const indexPrev = currentIndex === 0 ? currentIndex : currentIndex - 1;

      hideItems();
      showItems(indexPrev);
    }

    if (target.classList.contains("next")) {
      const indexNext =
        currentIndex === paginationNums.length - 1
          ? currentIndex
          : currentIndex + 1;

      hideItems();
      showItems(indexNext);
    }

    paginationNums.forEach((item, i, arr) => {
      if (target == item) {
        hideItems();
        showItems(i);
      }
    });
  }
});
