import { I18n } from "i18n-js";
import ru from "../../i18n/ru.json";
import en from "../../i18n/en.json";
import uk from "../../i18n/uk.json";

export const i18n = new I18n({
  ...ru,
  ...en,
  ...uk,
});

const getQueryParam = () => {
  const match = window.location.search.match(/[?&]lang=([^&]*)/);

  return match ? match[1] : null;
};

i18n.locale = getQueryParam() ?? "ru";

const langDropdown = document.querySelector(".lang-dropdown");
const langDropdownHead = langDropdown.querySelector(".lang-dropdown__head");
const langDropdownOptions = langDropdown.querySelectorAll(
  ".lang-dropdown__option",
);

// Select active document
const docsList = document.querySelectorAll(".terms[data-lang]");
const showActiveDoc = (locale) => {
  if (docsList.length > 0) {
    docsList.forEach((doc) => {
      doc.style.display = "none";

      if (doc.dataset.lang === locale) {
        doc.style.display = "block";
      }
    });
  }
};

const setSelectedStatus = (locale) => {
  langDropdownHead.innerHTML = locale;

  langDropdownOptions.forEach((el) => {
    el.classList.remove("selected");

    if (el.dataset.locale === locale) el.classList.add("selected");
  });
};

// Set functionality to dropdown
if (langDropdown) {
  langDropdown.addEventListener("click", (e) => {
    const target = e.target;

    if (target && target.matches(".lang-dropdown__option")) {
      i18n.locale = target.dataset.locale;

      // Set active status to dropdown
      setSelectedStatus(i18n.locale);

      // Show active doc
      showActiveDoc(i18n.locale);

      window.location.search = `lang=${i18n.locale}`;

      // Update localization in elements
      updateLocalization();
    }
  });
}

// Update localization in elements
const updateLocalization = () => {
  const i18nElements = document.querySelectorAll("[data-i18n]");
  if (i18nElements.length > 0) {
    i18nElements.forEach((element) => {
      const value = i18n.t(element.dataset.i18n);

      if (element.tagName === "INPUT") {
        element.placeholder = value;
      } else {
        element.innerHTML = value;
      }
    });
  }
};

showActiveDoc(i18n.locale);
setSelectedStatus(i18n.locale);
updateLocalization();
