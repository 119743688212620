import {
  disablePageScroll,
  enablePageScroll,
  getScrollState,
} from "scroll-lock/dist/scroll-lock.js";

const header = document.querySelector(".header");
const burgerButton = document.querySelector(".burger");

function toggleMenu(lockScroll = true) {
  header.classList.toggle("active");
  burgerButton.classList.toggle("active");

  if (lockScroll) {
    if (getScrollState()) {
      disablePageScroll(
        document.querySelector(".mobile-header .simplebar-content"),
      );
    } else {
      enablePageScroll();
    }
  }
}

const closeMenu = () => {
  header.classList.remove("active");
  burgerButton.classList.remove("active");

  enablePageScroll();
};

burgerButton.addEventListener("click", () => toggleMenu());

window.addEventListener("popstate", () => {
  closeMenu();
});
